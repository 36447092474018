import { Box, Button, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { Link } from "react-router-dom";

const primary = blueGrey[500];

const PageNotFound = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: primary,
            }}
        >
            <Typography variant="h1" style={{ color: "white" }}>
                404
            </Typography>
            <Typography variant="h5" style={{ color: "white" }}>
                Страница не найдена
            </Typography>
            <Button sx={{ mt: 2 }} variant="contained">
                <Link className="unstiled-link" to="/">На главную</Link>
            </Button>
        </Box>
    );
};

export default PageNotFound;
