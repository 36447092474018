import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { Container, Typography, Box, Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import CompanyService from "../../services/CompanyService";

const CompanyCreate = () => {
    const navigate = useNavigate();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [company, setCompany] = useState({
        name: "",
        address: "",
        work_time: "",
        comment: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        async function createCompany() {
            setLoadingSubmit(true);
            try {
                const new_company = await CompanyService.createCompany(company);
                enqueueSnackbar(<Typography>Компания успешно добавлена (ID: {new_company.data.id})</Typography>);
                navigate("/companies");
            } catch (e) {
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            } finally {
                setLoadingSubmit(false);
            }
        }
        createCompany();
    };
    return (
        <div>
            <Header />
            <Container maxWidth="xl" sx={{ pt: 4 }}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid container item spacing={3}>                            
                            <Grid item md={12} xs={12}>
                                <TextField
                                    id="name"
                                    name="name"
                                    required
                                    variant="standard"
                                    label="Название"
                                    fullWidth
                                    value={company.name}
                                    onChange={(event) => setCompany((old) => ({ ...old, name: event.target.value }))}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item md={10} xs={12}>
                                <TextField
                                    id="address"
                                    name="address"
                                    variant="standard"
                                    label="Адрес"
                                    fullWidth
                                    value={company.address}
                                    required
                                    onChange={(event) => setCompany((old) => ({ ...old, address: event.target.value }))}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <TextField
                                    id="work_time"
                                    name="work_time"
                                    variant="standard"
                                    label="Время работы"
                                    fullWidth
                                    value={company.work_time}
                                    onChange={(event) =>
                                        setCompany((old) => ({ ...old, work_time: event.target.value }))
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    id="comment"
                                    name="comment"
                                    variant="standard"
                                    label="Комментарий"
                                    fullWidth
                                    multiline
                                    value={company.comment}
                                    onChange={(event) => setCompany((old) => ({ ...old, comment: event.target.value }))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <LoadingButton
                        loading={loadingSubmit}
                        loadingPosition="end"
                        endIcon={<></>}
                        type="submit"
                        variant="contained"
                        sx={{ mt: 4 }}
                    >
                        Создать
                    </LoadingButton>
                </Box>
            </Container>
        </div>
    );
};

export default CompanyCreate;
