import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import {
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Link,
    Autocomplete,
    FormControl,
    TextField,
    Box,
} from "@mui/material";
import { Person, Smartphone, AlternateEmail } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import TelegramIcon from "@mui/icons-material/Telegram";

const CourierCard = (props) => {
    const ticket = props.ticket;
    const couriers = props.couriers;
    const setTicket = props.setTicket;
    const { store } = useContext(AuthContext);

    const handleCourierChange = (event, newValue) => {
        setTicket((old) => {
            return {
                ...old,
                courier_user: { ...newValue },
            };
        });
    };

    if (
        ticket.status.code_name !== "completed" &&
        ticket.status.code_name !== "new" &&
        store.user.permissions.includes("couriers.custom_perform_manager_role")
    ) {
        return (
            <List disablePadding dense>
                <ListItem dense disablePadding sx={{ mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[500] }}>
                            <Person />
                        </Avatar>
                    </ListItemAvatar>
                    <FormControl fullWidth variant="standard">
                        <Autocomplete
                            id="courier"
                            name="courier"
                            options={couriers}
                            onChange={handleCourierChange}
                            disableClearable
                            value={ticket.courier_user}
                            renderInput={(params) => {
                                return <TextField {...params} label="Курьер" variant="standard" />;
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <Box component="li" {...props}>
                                        {`${option.last_name} ${option.first_name}`}
                                    </Box>
                                );
                            }}
                            getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return `${option.last_name} ${option.first_name}`;
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                        />
                    </FormControl>
                </ListItem>
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <Smartphone />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.courier_user && ticket.courier_user.phone_number ? (
                                <Link href={"tel:" + ticket.courier_user.phone_number}>
                                    {ticket.courier_user.phone_number}
                                </Link>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <AlternateEmail />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.courier_user && ticket.courier_user.email ? (
                                <Link href={"mailto:" + ticket.courier_user.email}>{ticket.courier_user.email}</Link>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <TelegramIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.courier_user && ticket.courier_user.telegram_username ? (
                                <Link href={`https://t.me/${ticket.courier_user.telegram_username}`}>
                                    {"@" + ticket.courier_user.telegram_username}
                                </Link>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </List>
        );
    } else {
        return (
            <List disablePadding dense>
                <ListItem dense disablePadding sx={{ mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <Person />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.courier_user
                                ? ticket.courier_user.last_name + " " + ticket.courier_user.first_name
                                : "-"
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <Smartphone />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.courier_user && ticket.courier_user.phone_number ? (
                                <Link href={"tel:" + ticket.courier_user.phone_number}>
                                    {ticket.courier_user.phone_number}
                                </Link>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <AlternateEmail />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.courier_user && ticket.courier_user.email ? (
                                <Link href={"mailto:" + ticket.courier_user.email}>{ticket.courier_user.email}</Link>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <TelegramIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.courier_user && ticket.courier_user.telegram_username ? (
                                <Link href={`https://t.me/${ticket.courier_user.telegram_username}`}>
                                    {"@" + ticket.courier_user.telegram_username}
                                </Link>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </List>
        );
    }
};

export default CourierCard;
