import {
    Grid,
    Box,
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox,
    Autocomplete,
    createFilterOptions,
    Card,
    CardHeader,
    CardContent,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import TicketService from "../../services/TicketService";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AuthContext from "../../context/AuthContext";
import Layout from "../../components/Layout";
import NewTicketStore from "./store";
import { observer, Observer } from "mobx-react-lite";

const filter = createFilterOptions();
let newTicket = new NewTicketStore();

const CreateTicket = () => {
    const { store } = useContext(AuthContext);
    const navigate = useNavigate();
    const [serverData, setServerData] = useState({
        companies: [],
    });

    useEffect(() => {
        async function fetchCompanies() {
            try {
                const companies = await TicketService.fetchCompanies();
                setServerData((old) => ({ ...old, companies: companies.data }));
            } catch (e) {
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            }
        }
        fetchCompanies();
        return () => {
            newTicket = new NewTicketStore();
        };
    }, []);

    return (
        <Layout>
            <Box
                component="form"
                onSubmit={async (event) => {
                    const result = await newTicket.createTicket(event);
                    if (result.message) {
                        enqueueSnackbar(<Typography>{result.message}</Typography>);
                    }
                    if (result.success) {
                        navigate("/");
                    }
                }}
            >
                <Typography component="h1" variant="h5">
                    Создание заявки
                </Typography>
                <Grid container sx={{ mt: 1 }} spacing={3}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            id="task"
                            name="task"
                            required
                            label="Задание"
                            fullWidth
                            value={newTicket.task}
                            onChange={(event) => newTicket.setTask(event.target.value)}
                            autoFocus
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card variant="outlined">
                            <CardHeader title="Компания" sx={{ pb: 0 }} />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <FormControl required fullWidth variant="standard">
                                            <Autocomplete
                                                value={newTicket.company}
                                                onChange={async (event, newValue) => {
                                                    const result = await newTicket.companyChange(event, newValue);
                                                    if (!result.success) {
                                                        enqueueSnackbar(<Typography>{result.message}</Typography>);
                                                    }
                                                }}
                                                freeSolo
                                                options={serverData.companies}
                                                required
                                                id="company"
                                                name="company"
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        label="Название"
                                                        variant="standard"
                                                    />
                                                )}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);

                                                    const { inputValue } = params;

                                                    const isExisting = options.some(
                                                        (option) => inputValue === option.name
                                                    );
                                                    if (inputValue !== "" && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `Добавить "${inputValue}"`,
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                getOptionLabel={(option) => {
                                                    if (typeof option === "string") {
                                                        return option;
                                                    }
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    return option.name;
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            id="company_address"
                                            name="company_address"
                                            variant="standard"
                                            label="Адрес"
                                            fullWidth
                                            value={newTicket.companyAddress}
                                            onChange={(event) => newTicket.companyAddressChange(event.target.value)}
                                            error={newTicket.companyAddressError}
                                            helperText={newTicket.companyAddressError ? "Обязательное поле" : ""}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Card variant="outlined">
                            <CardHeader title="Контактное лицо" sx={{ pb: 0 }} />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth variant="standard">
                                            <Autocomplete
                                                value={newTicket.contactPerson}
                                                onChange={newTicket.contactPersonChange}
                                                id="contact_person"
                                                name="contact_person"
                                                freeSolo
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                disabled={newTicket.contactPersonDisabled}
                                                renderInput={(params) => {
                                                    return (
                                                        <Observer>
                                                            {() => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Имя"
                                                                    variant="standard"
                                                                    error={newTicket.contactPersonError}
                                                                    helperText={
                                                                        newTicket.contactPersonError
                                                                            ? "Обязательное поле"
                                                                            : ""
                                                                    }
                                                                />
                                                            )}
                                                        </Observer>
                                                    );
                                                }}
                                                options={newTicket.serverContactPersons}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props}>
                                                        {option.fullname}
                                                    </Box>
                                                )}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);

                                                    const { inputValue } = params;

                                                    const isExisting = options.some(
                                                        (option) => inputValue === option.fullname
                                                    );
                                                    if (inputValue !== "" && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            fullname: `Добавить "${inputValue}"`,
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                getOptionLabel={(option) => {
                                                    if (typeof option === "string") {
                                                        return option;
                                                    }
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    return option.fullname;
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            disabled={newTicket.contactPersonDisabled}
                                            id="contact_person_phone_number"
                                            name="contact_person_phone_number"
                                            variant="standard"
                                            label="Телефон"
                                            fullWidth
                                            value={newTicket.phoneNumber}
                                            onChange={(event) => newTicket.phoneNumberChange(event.target.value)}
                                            error={newTicket.phoneNumberError}
                                            helperText={newTicket.phoneNumberError ? "Обязательное поле" : ""}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            id="comment"
                            name="comment"
                            label="Комментарий"
                            fullWidth
                            value={newTicket.comment}
                            onChange={(event) => newTicket.setComment(event.target.value)}
                            multiline
                            rows={3}
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <DatePicker
                                label="Срок исполнения"
                                value={newTicket.expiresAt}
                                onChange={(newValue) => newTicket.setNewExpirationDate(newValue)}
                                slotProps={{
                                    textField: {
                                        error: newTicket.expiresAtError,
                                        helperText: newTicket.expiresAtError ? "Неверный формат даты" : "",
                                        required: true,
                                    },
                                }}
                                format="LL"
                                views={["year", "month", "day"]}
                                disablePast
                            />
                        </FormControl>
                    </Grid>
                    {store.user.permissions.includes("couriers.custom_create_express_ticket") && (
                        <Grid item md={12} xs={12}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={newTicket.isExpress}
                                            onChange={(event) => newTicket.setIsExpress(event.target.checked)}
                                        />
                                    }
                                    label="Срочная заявка"
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                <LoadingButton
                    loading={newTicket.loadingSubmit}
                    loadingPosition="end"
                    endIcon={<></>}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3 }}
                >
                    Подтвердить
                </LoadingButton>
            </Box>
        </Layout>
    );
};

export default observer(CreateTicket);
