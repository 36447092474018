import { Grid, Typography, Divider, Chip, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import UserService from "../services/UserService";
import { enqueueSnackbar } from "notistack";
import Layout from "../components/Layout";

const ProfilePage = () => {
    const { store } = useContext(AuthContext);
    const [tgChecked, setTgChecked] = useState(store.user.telegram_id ? true : false);
    const [tgDisabled, setTgDisabled] = useState(false);

    const handleTgId = (event) => {
        if (!store.user.telegram_id) {
            async function getConfirmCode() {
                let confirm_code;
                try {
                    const confirm_code_data = await UserService.get_tg_confirm_code();
                    confirm_code = confirm_code_data.data.telegram_confirm_code;
                    setTgChecked(true);
                } catch (e) {
                    enqueueSnackbar(<Typography>Ошибка запроса кода подтверждения</Typography>);
                    return;
                }
                setTgDisabled(true);
                window.open(`https://t.me/${process.env.REACT_APP_BOT_NAME}?start=${btoa(confirm_code)}`);
            }
            getConfirmCode();
        } else {
            async function resetTelegramId() {
                try {
                    await UserService.editProfile(store.user.user_id, null, true);
                    setTgChecked(false);
                    store.user.telegram_id = null;
                } catch (e) {
                    enqueueSnackbar(<Typography>Ошибка изменения пользователя</Typography>);
                }
            }
            resetTelegramId();
        }
    };

    return (
        <Layout>
            <Grid container spacing={3}>
                <Grid item xs={12} md={10}>
                    <Typography variant="h5" gutterBottom>
                        ФИО
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {store.user.last_name ? `${store.user.last_name} ${store.user.first_name}` : "-"}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography variant="h5" gutterBottom>
                        Имя
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {store.user.username}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom>
                        Почта
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {store.user.email ? store.user.email : "-"}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom>
                        Телефон
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {store.user.phone_number ? store.user.phone_number : "-"}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant="h5" gutterBottom>
                        Группы
                    </Typography>
                    <Typography component="div" variant="body1" gutterBottom sx={{ mb: 1 }}>
                        {store.user.groups.length
                            ? store.user.groups.map((el) => <Chip key={el.id} label={el.name} sx={{ mr: 1 }} />)
                            : "-"}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Уведомления
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        В сервисе предусмотрен механизм оповещения о событиях посредством телеграма. Для этого
                        происходит привязка вашего telegram id к аккаунту.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Чтобы начать получать уведомления, необходимо отметить поле ниже. После этого у вас откроется
                        окно с ботом. Процесс привязки будет запущен автоматически. После вы получите сообщение от бота
                        об успешности процедуры. Для отображения изменений на сайте требуется перезагрузка страницы. В
                        случае изменения никнейма в телеграме необходимо повторить вышеописанные действия.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Чтобы отменить подписку, уберите отметку в поле.
                    </Typography>
                    <FormControl>
                        <FormControlLabel
                            sx={{ mr: 1 }}
                            control={<Checkbox disabled={tgDisabled} checked={tgChecked} onChange={handleTgId} />}
                            label="Получать уведомления"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default ProfilePage;
