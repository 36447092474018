import React from "react";
import Header from "../Header";
import { Box } from "@mui/material";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <div>
            <Header />
            <Box sx={{ py: 4, px: { md: 5, xs: 2 } }}>{children}</Box>
        </div>
    );
};

export default Layout;
