import { Card, Typography, Box } from "@mui/material";
import dayjs from "dayjs";

const Comments = (props) => {
    return (
        <div>            
            {props.comments.map((el) => {
                return (
                    <Card variant="outlined" sx={{ p: 2, mb: 1 }} key={el.id}>
                        <Box sx={{ mb: 1 }}>
                            <Typography variant="caption">
                                {el.user.last_name} {el.user.first_name} - {dayjs(el.created_at).format("LLL")}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography>{el.comment}</Typography>
                        </Box>
                    </Card>
                );
            })}
        </div>
    );
};

export default Comments;
