import { Typography, Box } from "@mui/material";
import {
    FiberNew,
    Done,
    DirectionsRun,
    HourglassEmpty,
} from "@mui/icons-material";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.locale("ru");
dayjs.extend(localizedFormat);

const StatusElement = (props) => {
    const status = props.status
    
    if (status.code_name === "new") {
        return (
            <Typography gutterBottom component="div">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FiberNew color="info" />
                    <Typography variant="body1">{status.name}</Typography>
                </Box>
            </Typography>
        );
    } else if (status.code_name === "assigned_to_courier") {
        return (
            <Typography gutterBottom component="div">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <HourglassEmpty color="warning" />
                    <Typography variant="body1">{status.name}</Typography>
                </Box>
            </Typography>
        );
    } else if (status.code_name === "in_progress") {
        return (
            <Typography gutterBottom component="div">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DirectionsRun color="warning" />
                    <Typography variant="body1">{status.name}</Typography>
                </Box>
            </Typography>
        );
    } else if (status.code_name === "completed") {
        return (
            <Typography gutterBottom component="div">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Done color="success" />
                    <Typography variant="body1">{status.name}</Typography>
                </Box>
            </Typography>
        );
    } else {
        return (
            <Typography variant="body1" gutterBottom>
                {status.name}
            </Typography>
        );
    }
};

export default StatusElement;