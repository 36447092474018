import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Container, Typography, Button, Tooltip, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CompanyService from "../../services/CompanyService";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const Companies = () => {
    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate();
    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Название", width: 250 },
        { field: "address", headerName: "Адрес", width: 250 },
        { field: "work_time", headerName: "Время работы", width: 150 },
        { field: "comment", headerName: "Комментарий", width: 500 },
    ];
    useEffect(() => {
        async function fetchCompanies() {
            try {
                const companies = await CompanyService.fetchCompanies();
                setCompanies(companies.data);
            } catch (e) {
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            }
        }
        fetchCompanies();
    }, []);

    return (
        <div>
            <Header />
            <Container maxWidth="xl" sx={{ pb: 4 }}>
                <Typography component="h1" variant="h5" sx={{ mt: 4, mb: 2, display: "flex", alignItems: "center" }}>
                    Компании{" "}
                    <Tooltip title="Добавить компанию">
                        <IconButton sx={{ ml: 1 }} onClick={() => navigate('/company-create')}>
                            <AddCircleIcon color="primary" fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Typography>
                <DataGrid
                    rows={companies}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                        row: {
                            style: {
                                cursor: "pointer",
                            },
                        },
                    }}
                    onRowSelectionModelChange={(id) => {
                        navigate(`/companies/${id}`);
                    }}
                />
            </Container>
        </div>
    );
};

export default Companies;
