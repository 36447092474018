import { Tooltip, IconButton, Drawer, Box, MenuItem, MenuList, Typography, Divider, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const MyMenuIcon = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { store } = useContext(AuthContext);
    return (
        <Box sx={props.sx}>
            <Tooltip title="Меню">
                <IconButton color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <MenuIcon />
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)} onClick={() => setAnchorEl(null)}>
                {store.user.permissions.includes("couriers.custom_perform_initiator_role") ||
                store.user.permissions.includes("couriers.custom_perform_manager_role") ? (
                    <MenuItem onClick={() => navigate("/create-ticket")}>Создать заявку</MenuItem>
                ) : (
                    <MenuItem sx={{ display: "none" }}></MenuItem>
                )}
                {store.user.permissions.includes("couriers.custom_perform_manager_role") ? (
                    <MenuItem
                        onClick={() => {
                            navigate("/companies");
                        }}
                    >
                        Управление компаниями
                    </MenuItem>
                ) : (
                    <MenuItem sx={{ display: "none" }}></MenuItem>
                )}
            </Menu>
            {/* <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
                <Box sx={{ width: 250 }}>
                    <MenuList>
                        {store.user.permissions.includes("couriers.custom_perform_initiator_role") ||
                        store.user.permissions.includes("couriers.custom_perform_manager_role") ? (
                            <Box>
                                <Typography sx={{ ml: 2 }} variant="h6" gutterBottom>
                                    Заявки
                                </Typography>
                                <MenuItem
                                    onClick={() => {
                                        navigate("/create-ticket");
                                        setOpen(false);
                                    }}
                                >
                                    Создать заявку
                                </MenuItem>
                                <Divider />
                            </Box>
                        ) : (
                            <></>
                        )}
                        {store.user.permissions.includes("couriers.custom_perform_manager_role") ? (
                            <Box>
                                <Typography sx={{ ml: 2 }} variant="h6" gutterBottom>
                                    Админ-панель
                                </Typography>
                                <MenuItem
                                    onClick={() => {
                                        navigate("/companies");
                                        setOpen(false);
                                    }}
                                >
                                    Управление компаниями
                                </MenuItem>
                                <Divider />
                            </Box>
                        ) : (
                            <></>
                        )}
                    </MenuList>
                </Box>
            </Drawer> */}
        </Box>
    );
};

export default MyMenuIcon;
