import api from "../http";

export default class CompanyService {
    static async fetchCompanies() {
        return api.get('/companies')
    }

    static async fetchCompany(id) {
        return api.get(`/companies/${id}`)
    }

    static async updateCompany(id, companyInstance) {
        return api.put(`/companies/${id}/`, companyInstance)
    }

    static async createCompany(companyInstance) {
        return api.post('/companies/', companyInstance)
    }

    static async removeCompany(id) {
        return api.delete(`/companies/${id}`)
    }
}