import api from "../http";

export default class UserService {
    static async get_tg_confirm_code() {
        return api.get("/confirm-code");
    }

    static async editProfile(userId, telegramId, clearTgUsername) {
        let data = {
            telegram_id: telegramId,
        };
        if (clearTgUsername) data.telegram_username = null;
        return api.put(`/profile/${userId}/`, data);
    }
}
