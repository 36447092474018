import { createContext } from "react";
import Store from "../store/store";

const store = new Store()
const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const contextData = {
        store: store
    }

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}