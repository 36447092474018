import { makeAutoObservable } from "mobx"
import AuthService from "../services/AuthService"
import { jwtDecode } from "jwt-decode"
import axios from "axios"
import { API_URL } from "../http"

export default class Store {
    user = localStorage.getItem('authTokens') ? jwtDecode(JSON.parse(localStorage.getItem('authTokens')).access) : {}
    isAuth = !!localStorage.getItem('authTokens')
    isLoading = false

    constructor() {
        makeAutoObservable(this)
    }

    setAuth = (bool) => {
        this.isAuth = bool
    }

    setUser = (user) => {
        this.user = user
    }

    setLoading = (bool) => {
        this.isLoading = bool
    }

    login = async (username, password) => {
        let result = {}
        try {
            const response = await AuthService.login(username, password)
            localStorage.setItem('authTokens', JSON.stringify(response.data));
            this.setAuth(true)
            this.setUser(jwtDecode(response.data.access))
            result.success = true
        } catch (e) {
            result.success = false
            if (e.response.status === 401) {
                result.message = 'Неверный логин или пароль'
            } else {
                result.message = 'Внутрення ошибка сервера'
            }
        }
        return result
    }

    logout = () => {
        this.setAuth(false)
        this.setUser({})
        localStorage.removeItem('authTokens')
    }

    checkAuth = async () => {
        this.setLoading(true)
        try {
            const initial_token = JSON.parse(localStorage.getItem('authTokens'))
            const response = await axios.post(`${API_URL}/token/refresh/`, { refresh: initial_token.refresh })
            initial_token.access = response.data.access
            localStorage.setItem('authTokens', JSON.stringify(initial_token))

            this.setAuth(true)
            this.setUser(jwtDecode(response.data.access))
        } catch (e) {
            console.log(e)
            localStorage.removeItem('authTokens')
            window.location.href = '/login'
        } finally {
            this.setLoading(false)
        }
    }
}