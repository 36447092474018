import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import {
    Box,
    FormControl,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Link,
    Autocomplete,
    TextField,
    createFilterOptions,
} from "@mui/material";
import { Person, Smartphone, ChatBubble, AlternateEmail } from "@mui/icons-material";
import { blue } from '@mui/material/colors';

const filter = createFilterOptions();

const ContactPersonCard = (props) => {
    const formErrors = props.formErrors;
    const setFormErrors = props.setFormErrors;
    const ticket = props.ticket;
    const serverContactPersons = props.serverContactPersons;
    const setTicket = props.setTicket;
    const { store } = useContext(AuthContext);

    const handleContactPersonFullnameChange = (event, newValue) => {
        setFormErrors((old) => ({ ...old, contact_person_fullname: false }));
        let contact_person = ticket.contact_person;
        if ((newValue && newValue.inputValue) || !newValue) {
            setTicket((old) => {
                contact_person.id = null;
                contact_person.fullname = newValue ? newValue.inputValue : "";
                contact_person.phone_number = null;
                contact_person.mail = null;
                contact_person.comment = null;
                return {
                    ...old,
                    contact_person: contact_person,
                };
            });
        } else {
            setTicket((old) => {
                return {
                    ...old,
                    contact_person: { ...newValue },
                };
            });
        }
    };

    const handleContactPersonPhoneNumberChange = (event) => {
        setFormErrors((old) => ({ ...old, contact_person_phone_number: false }));
        setTicket((old) => {
            let contact_person = old.contact_person;
            contact_person.phone_number = event.target.value;
            return {
                ...old,
                contact_person: contact_person,
            };
        });
    };

    const handleContactPersonMailChange = (event) => {
        setTicket((old) => {
            let contact_person = old.contact_person;
            contact_person.mail = event.target.value;
            return {
                ...old,
                contact_person: contact_person,
            };
        });
    };

    const handleContactPersonCommentChange = (event) => {
        setTicket((old) => {
            let contact_person = old.contact_person;
            contact_person.comment = event.target.value;
            return {
                ...old,
                contact_person: contact_person,
            };
        });
    };

    if (
        ticket.status.code_name !== "completed" &&
        (store.user.permissions.includes("couriers.custom_perform_manager_role") ||
            store.user.permissions.includes("couriers.custom_perform_initiator_role"))
    ) {
        return (
            <List disablePadding dense>
                <ListItem dense disablePadding>
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: blue[500]}}>
                            <Person />
                        </Avatar>
                    </ListItemAvatar>
                    <FormControl fullWidth variant="standard">
                        <Autocomplete
                            id="contact_person"
                            name="contact_person"
                            onChange={handleContactPersonFullnameChange}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            value={ticket.contact_person.fullname ? ticket.contact_person : null}
                            options={serverContactPersons}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Полное имя"
                                        variant="standard"
                                        error={formErrors.contact_person_fullname}
                                        helperText={
                                            formErrors.contact_person_fullname
                                                ? "Обязательное поле для назначения контактного лица"
                                                : ""
                                        }
                                    />
                                );
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.fullname}
                                </Box>
                            )}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;

                                const isExisting = options.some((option) => inputValue === option.fullname);
                                if (inputValue !== "" && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        fullname: `Добавить "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.fullname;
                            }}
                        />
                    </FormControl>
                </ListItem>
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: blue[500]}}>
                            <Smartphone />
                        </Avatar>
                    </ListItemAvatar>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            id="contact_person_phone_number"
                            name="contact_person_phone_number"
                            variant="standard"
                            label="Телефон"
                            fullWidth
                            value={ticket.contact_person.phone_number ? ticket.contact_person.phone_number : ""}
                            onChange={handleContactPersonPhoneNumberChange}
                            error={formErrors.contact_person_phone_number}
                            helperText={
                                formErrors.contact_person_phone_number
                                    ? "Обязательное поле для назначения контактного лица"
                                    : ""
                            }
                        />
                    </FormControl>
                </ListItem>
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: blue[500]}}>
                            <AlternateEmail />
                        </Avatar>
                    </ListItemAvatar>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            id="contact_person_mail"
                            name="contact_person_mail"
                            variant="standard"
                            label="Почта"
                            fullWidth
                            value={ticket.contact_person.mail ? ticket.contact_person.mail : ""}
                            onChange={handleContactPersonMailChange}
                        />
                    </FormControl>
                </ListItem>
                <ListItem dense disablePadding sx={{ mt: 2, mb: 0.5 }}>
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: blue[500]}}>
                            <ChatBubble />
                        </Avatar>
                    </ListItemAvatar>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            id="contact_person_comment"
                            name="contact_person_comment"
                            variant="standard"
                            label="Комментарий"
                            fullWidth
                            value={ticket.contact_person.comment ? ticket.contact_person.comment : ""}
                            onChange={handleContactPersonCommentChange}
                        />
                    </FormControl>
                </ListItem>
            </List>
        );
    } else {
        return (
            <List disablePadding dense>
                <ListItem dense disablePadding sx={{ mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <Person />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.contact_person && ticket.contact_person.fullname
                                ? ticket.contact_person.fullname
                                : "-"
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <Smartphone />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.contact_person && ticket.contact_person.phone_number ? (
                                <Link href={"tel:" + ticket.contact_person.phone_number}>
                                    {ticket.contact_person.phone_number}
                                </Link>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <AlternateEmail />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.contact_person && ticket.contact_person.mail ? (
                                <Link href={"mailto:" + ticket.contact_person.mail}>{ticket.contact_person.mail}</Link>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <ChatBubble />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 2 }}
                        primary={
                            ticket.contact_person && ticket.contact_person.comment ? ticket.contact_person.comment : "-"
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </List>
        );
    }
};

export default ContactPersonCard;
