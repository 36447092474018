import api from "../http";

export default class TicketService {
    static async fetch_tickets(page, pageSize, ordering = "", search = "") {
        return api.get("/ticket-list", {
            params: { page: page, page_size: pageSize, ordering: ordering, search: search },
        });
    }

    static async fetchTicket(id) {
        return api.get(`/ticket/${id}`);
    }

    static async createTicket(task, company, contact_person, expires_at, is_express, comment) {
        let data = {
            task: task,
            company: company,
            contact_person: contact_person,
            expires_at: expires_at,
            is_express: is_express,
        };
        if (comment.trim()) data.comment = comment;
        return api.post("/ticket-create", data);
    }

    static async updateTicket(ticketId, contactPerson, courierUserId, expires_at, comment) {
        let data = {
            contact_person: contactPerson,
            courier_user: courierUserId,
            expires_at: expires_at,
        }
        if (comment.trim()) data.comment = comment;
        return api.put(`/tickets/${ticketId}/`, data);
    }

    static async fetchCompanies() {
        return api.get("/company");
    }

    static async fetchCompanyContactPersons(company) {
        return api.get("/contact-person", { params: { company: company } });
    }

    static async fetchCouriers() {
        return api.get("/couriers");
    }

    static async assignToCourier(id, courier, departure_date) {
        return api.post("/exec-action", {
            id: id,
            courier: courier,
            departure_date: departure_date,
            action: "assign_courier",
        });
    }

    static async inProgress(id) {
        return api.post("/exec-action", { id: id, action: "in_progress" });
    }

    static async complete(id) {
        return api.post("/exec-action", { id: id, action: "complete" });
    }
}
