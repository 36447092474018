import { IconButton, Avatar, Menu, MenuItem, Divider, ListItemIcon, Tooltip } from "@mui/material";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

const ProfileIcon = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { store } = useContext(AuthContext);
    return (
        <>
            <>
                <Tooltip title="Пользователь">
                    <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <Avatar>{store.user.username[0]}</Avatar>
                    </IconButton>
                </Tooltip>
            </>
            <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)} onClick={() => setAnchorEl(null)}>
                <MenuItem onClick={() => navigate("/profile")}>
                    <ListItemIcon>
                        <PersonIcon sx={{ mr: 2 }} />
                    </ListItemIcon>
                    Профиль
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        store.logout();
                        navigate("/login");
                    }}
                >
                    <ListItemIcon>
                        <LogoutIcon sx={{ mr: 2 }} />
                    </ListItemIcon>
                    Выйти
                </MenuItem>
            </Menu>
        </>
    );
};

export default ProfileIcon;
