import { useEffect, useState } from "react";
import TicketService from "../services/TicketService";
import { Box, Typography, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/ru";
import { Bolt, FiberNew, DirectionsRun, Done, HourglassEmpty } from "@mui/icons-material";
import Layout from "../components/Layout";

dayjs.locale("ru");
dayjs.extend(localizedFormat);

const HomePage = () => {
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 0,
        pageSize: 10,
        ordering: "",
        search: "",
    });
    const navigate = useNavigate();

    const sortModel = [{ field: "id", sort: "desc" }];

    useEffect(() => {
        handleOrderingChange(sortModel);
    }, []);

    useEffect(() => {
        async function fetchTickets() {
            try {
                setPageState((old) => ({ ...old, isLoading: true }));
                const tickets = await TicketService.fetch_tickets(
                    pageState.page + 1,
                    pageState.pageSize,
                    pageState.ordering,
                    pageState.search
                );
                setPageState((old) => ({
                    ...old,
                    data: tickets.data.results,
                    total: tickets.data.count,
                }));
            } catch (e) {
                if (e?.response?.status === 404) {
                    setPageState((old) => ({ ...old, page: 0 }));
                } else {
                    enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
                }
            } finally {
                setPageState((old) => ({ ...old, isLoading: false }));
            }
        }
        fetchTickets();
    }, [pageState.page, pageState.pageSize, pageState.ordering, pageState.search]);

    const columns = [
        {
            field: "expires_at",
            headerName: "Срок исполнения",
            width: 200,
            valueGetter: (param) => {
                return param.value ? dayjs(param.value).format("LL") : "";
            },
        },
        {
            field: "task",
            headerName: "Задание",
            width: 200,
            renderCell: (param) => {
                if (param.row.is_express) {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Срочная заявка" placement="top">
                                <Bolt color="warning" />
                            </Tooltip>{" "}
                            {param.value}
                        </Box>
                    );
                }
                return param.value;
            },
        },
        {
            field: "status",
            headerName: "Статус",
            renderCell: (param) => {
                if (param.value.code_name === "new") {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FiberNew color="info" />
                            {param.value.name}
                        </Box>
                    );
                } else if (param.value.code_name === "assigned_to_courier") {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <HourglassEmpty color="warning" />
                            {param.value.name}
                        </Box>
                    );
                } else if (param.value.code_name === "in_progress") {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <DirectionsRun color="warning" />
                            {param.value.name}
                        </Box>
                    );
                } else if (param.value.code_name === "completed") {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Done color="success" />
                            {param.value.name}
                        </Box>
                    );
                }
                return param.value.name;
            },
            width: 180,
        },
        {
            field: "company",
            headerName: "Компания",
            valueGetter: (param) => param?.value?.name,
            width: 150,
        },
        {
            field: "contact_person",
            headerName: "Контактное лицо",
            valueGetter: (param) => param?.value?.fullname,
            flex: 1,
        },
        {
            field: "initiator_user",
            headerName: "Инициатор",
            valueGetter: (param) => param?.value?.last_name + " " + param?.value?.first_name,
            flex: 1,
        },
        {
            field: "courier_user",
            headerName: "Курьер",
            valueGetter: (param) => {
                if (param.value) {
                    return param?.value?.last_name + " " + param?.value?.first_name;
                }
            },
            width: 200,
        },
        {
            field: "created_at",
            headerName: "Создана",
            width: 200,
            valueGetter: (param) => {
                return dayjs(param.value).format("LLL");
            },
        },
        {
            field: "departure_date",
            headerName: "Дата выезда",
            width: 200,
            valueGetter: (param) => {
                return param.value ? dayjs(param.value).format("LL") : "";
            },
        },
    ];

    const handleOrderingChange = (newModel) => {
        setPageState((old) => {
            const ordering = newModel.map((el) => {
                if (["company", "status"].includes(el.field)) {
                    return el.sort === "asc" ? `${el.field}__name` : `-${el.field}__name`;
                } else if (el.field === "contact_person") {
                    return el.sort === "asc" ? `${el.field}__fullname` : `-${el.field}__fullname`;
                } else if (["initiator_user", "courier_user"].includes(el.field)) {
                    return el.sort === "asc" ? `${el.field}__last_name` : `-${el.field}__last_name`;
                }
                return el.sort === "asc" ? el.field : "-" + el.field;
            });
            return { ...old, ordering: ordering.join(",") };
        });
    };

    return (
        <Layout>
            <Typography component="h1" variant="h5">
                Заявки
            </Typography>
            <DataGrid            
                autoHeight
                sx={{
                    mt: 3,
                }}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                    row: {
                        style: {
                            cursor: "pointer",
                        },
                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: sortModel,
                    },
                }}
                rows={pageState.data}
                rowCount={pageState.total}
                loading={pageState.isLoading}
                pageSizeOptions={[10, 30, 50]}
                paginationModel={pageState}
                paginationMode="server"
                columns={columns}                
                onPaginationModelChange={(newModel) => {
                    setPageState((old) => ({
                        ...old,
                        page: newModel.page,
                        pageSize: newModel.pageSize,
                    }));
                }}
                sortingMode="server"
                onSortModelChange={handleOrderingChange}
                filterMode="server"
                onFilterModelChange={(newModel) => {
                    setPageState((old) => ({
                        ...old,
                        search: newModel.quickFilterValues.join(" "),
                    }));
                }}
                disableColumnFilter
                onRowSelectionModelChange={(id) => {
                    navigate(`/ticket/${id}`);
                }}
            />
        </Layout>
    );
};

export default HomePage;
