import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TicketService from "../services/TicketService";
import {
    Typography,
    Box,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Link,
    FormControl,
    ButtonGroup,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import AuthContext from "../context/AuthContext";
import { Bolt, Person, Smartphone, Apartment, Home, AccessTime, ChatBubble, AlternateEmail } from "@mui/icons-material";
import ChangeStatus from "../components/detail_ticket/ChangeStatus";
import StatusElement from "../components/utils/StatusElement";
import ContactPersonCard from "../components/detail_ticket/ContactPersonCard";
import CourierCard from "../components/detail_ticket/CourierCard";
import Layout from "../components/Layout";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Comments from "../components/detail_ticket/Comments";
import SendIcon from "@mui/icons-material/Send";
import LinkIcon from "@mui/icons-material/Link";
import TelegramIcon from "@mui/icons-material/Telegram";

const DetailTicket = () => {
    const navigate = useNavigate();
    const { store } = useContext(AuthContext);
    const params = useParams();
    const [ticket, setTicket] = useState(null);
    const [newComment, setNewComment] = useState("");
    const [serverContactPersons, setServerContactPersons] = useState([]);
    const [serverCouriers, setServerCouriers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({
        contact_person_fullname: false,
        contact_person_phone_number: false,
    });
    const [addressOpen, setAddressOpen] = useState(false);

    const handleTicketSave = () => {
        let error_flag = false;
        if (ticket.contact_person.fullname && !ticket.contact_person.phone_number) {
            setFormErrors((old) => ({ ...old, contact_person_phone_number: true }));
            error_flag = true;
        }
        if (ticket.contact_person.phone_number && !ticket.contact_person.fullname) {
            setFormErrors((old) => ({ ...old, contact_person_fullname: true }));
            error_flag = true;
        }
        if (error_flag) return;

        // проверим срок исполнения
        if (typeof ticket.expires_at === "object" && (!ticket.expires_at || !ticket.expires_at.isValid())) {
            enqueueSnackbar(<Typography>Неверный формат срока исполнения</Typography>);
            return;
        }

        setLoading(true);
        async function updateTicket() {
            try {
                let contact_person = ticket.contact_person;
                contact_person.company = ticket.company.id;
                if (!ticket.contact_person.fullname && !ticket.contact_person.phone_number) {
                    contact_person = null;
                }

                const ticket_data = await TicketService.updateTicket(
                    ticket.id,
                    contact_person,
                    ticket.courier_user ? ticket.courier_user.id : null,
                    typeof ticket.expires_at === "object" ? ticket.expires_at.format("YYYY-MM-DD") : ticket.expires_at,
                    newComment
                );
                setNewComment("");
                setTicket({
                    ...ticket_data.data,
                    contact_person: ticket_data.data.contact_person ? ticket_data.data.contact_person : {},
                });
                const contactPersons = await TicketService.fetchCompanyContactPersons(ticket.company.id);
                setServerContactPersons(contactPersons.data);

                enqueueSnackbar(<Typography>Данные обновлены</Typography>);
            } catch (e) {
                console.log(e);
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            } finally {
                setLoading(false);
            }
        }
        updateTicket();
    };

    useEffect(() => {
        async function fetchTicket() {
            try {
                const ticket = await TicketService.fetchTicket(params.id);
                setTicket({
                    ...ticket.data,
                    contact_person: ticket.data.contact_person ? ticket.data.contact_person : {},
                });

                const contactPersons = await TicketService.fetchCompanyContactPersons(ticket.data.company.id);
                setServerContactPersons(contactPersons.data);
            } catch (e) {
                if (e.response.status === 404) {
                    navigate("*");
                } else {
                    enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
                }
            }
        }
        async function fetchCouriers() {
            try {
                const couriers = await TicketService.fetchCouriers();
                setServerCouriers(couriers.data);
            } catch (e) {
                enqueueSnackbar(<Typography>Ошибка запроса курьеров</Typography>);
            }
        }
        fetchCouriers();
        fetchTicket();
    }, []);

    return (
        <Layout>
            {ticket && (
                <Box component="div">
                    {ticket.status.code_name !== "completed" && (
                        <ButtonGroup variant="outlined" sx={{ mb: 2 }}>
                            <ChangeStatus ticket={ticket} setTicket={setTicket} serverCouriers={serverCouriers} />
                            <LoadingButton onClick={handleTicketSave} loading={loading}>
                                Сохранить
                            </LoadingButton>
                        </ButtonGroup>
                    )}

                    <Grid container spacing={3}>
                        <Grid container item spacing={3}>
                            <Grid item md={10} xs={12}>
                                <Typography variant="h4" gutterBottom>
                                    Задание
                                </Typography>
                                {ticket.is_express ? (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Bolt color="warning" />
                                        <Typography variant="body1" gutterBottom>
                                            {ticket.task}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography variant="body1" gutterBottom>
                                        {ticket.task}
                                    </Typography>
                                )}
                                <Divider />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Typography variant="h4" gutterBottom>
                                    Статус
                                </Typography>
                                <StatusElement status={ticket.status} />
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Создана
                                </Typography>
                                <div>
                                    <Typography variant="body1" gutterBottom>
                                        {dayjs(ticket.created_at).format("LLL")}
                                    </Typography>
                                    <Divider />
                                </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Срок исполнения
                                </Typography>
                                {ticket.status.code_name !== "completed" &&
                                store.user.permissions.includes("couriers.custom_perform_manager_role") ? (
                                    <FormControl fullWidth>
                                        <DatePicker
                                            value={dayjs(ticket.expires_at)}
                                            onChange={(newValue) =>
                                                setTicket({
                                                    ...ticket,
                                                    expires_at: newValue,
                                                })
                                            }
                                            format="LL"
                                            views={["year", "month", "day"]}
                                        />
                                    </FormControl>
                                ) : (
                                    <div>
                                        <Typography variant="body1" gutterBottom>
                                            {ticket.expires_at ? dayjs(ticket.expires_at).format("LL") : "-"}
                                        </Typography>
                                        <Divider />
                                    </div>
                                )}
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Дата выезда
                                </Typography>
                                <div>
                                    <Typography variant="body1" gutterBottom>
                                        {ticket.departure_date ? dayjs(ticket.departure_date).format("LL") : "-"}
                                    </Typography>
                                    <Divider />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item md={6} xs={12}>
                                <Card variant="outlined">
                                    <CardHeader title="Инициатор" />
                                    <CardContent>
                                        <List disablePadding dense>
                                            <ListItem dense disablePadding sx={{ mb: 1 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Person />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ ml: 2 }}
                                                    primary={
                                                        ticket.initiator_user.last_name +
                                                        " " +
                                                        ticket.initiator_user.first_name
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                            <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Smartphone />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ ml: 2 }}
                                                    primary={
                                                        ticket.initiator_user.phone_number ? (
                                                            <Link href={"tel:" + ticket.initiator_user.phone_number}>
                                                                {ticket.initiator_user.phone_number}
                                                            </Link>
                                                        ) : (
                                                            "-"
                                                        )
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                            <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <AlternateEmail />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ ml: 2 }}
                                                    primary={
                                                        ticket.initiator_user.email ? (
                                                            <Link href={"mailto:" + ticket.initiator_user.email}>
                                                                {ticket.initiator_user.email}
                                                            </Link>
                                                        ) : (
                                                            "-"
                                                        )
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                            <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <TelegramIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ ml: 2 }}
                                                    primary={
                                                        ticket.initiator_user.telegram_username ? (
                                                            <Link
                                                                href={`https://t.me/${ticket.initiator_user.telegram_username}`}
                                                            >
                                                                {"@" + ticket.initiator_user.telegram_username}
                                                            </Link>
                                                        ) : (
                                                            "-"
                                                        )
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Card variant="outlined">
                                    <CardHeader title="Курьер" />
                                    <CardContent>
                                        <CourierCard ticket={ticket} couriers={serverCouriers} setTicket={setTicket} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid container item spacing={3}>
                            <Grid item md={6} xs={12}>
                                <Card variant="outlined">
                                    <CardHeader title="Компания" />
                                    <CardContent>
                                        <List disablePadding dense>
                                            <ListItem dense disablePadding sx={{ mb: 1 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Apartment />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText sx={{ ml: 2 }} primary={ticket.company.name} />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                            <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Home />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ ml: 2 }}
                                                    primary={
                                                        ticket.company.address ? (
                                                            <div>
                                                                {ticket.company.address}{" "}
                                                                <Tooltip
                                                                    title={addressOpen ? "Скопировано!" : "Скопировать"}
                                                                    placement="top"
                                                                    sx={{ ml: 1 }}
                                                                >
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setAddressOpen(true);
                                                                            navigator.clipboard.writeText(
                                                                                ticket.company.address
                                                                            );
                                                                            setTimeout(() => {
                                                                                setAddressOpen(false);
                                                                            }, 3000);
                                                                        }}
                                                                    >
                                                                        <LinkIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                            <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <AccessTime />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ ml: 2 }}
                                                    primary={ticket.company.work_time ? ticket.company.work_time : "-"}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                            <ListItem dense disablePadding sx={{ mt: 2, mb: 1 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <ChatBubble />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ ml: 2 }}
                                                    primary={ticket.company.comment ? ticket.company.comment : "-"}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Card variant="outlined">
                                    <CardHeader title="Контактное лицо" />
                                    <CardContent>
                                        <ContactPersonCard
                                            ticket={ticket}
                                            setTicket={setTicket}
                                            formErrors={formErrors}
                                            setFormErrors={setFormErrors}
                                            serverContactPersons={serverContactPersons}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            Комментарии
                        </Typography>
                        {ticket.status.code_name !== "completed" && (
                            <FormControl variant="outlined" fullWidth sx={{ mb: 3 }}>
                                <InputLabel>Оставить комментарий</InputLabel>
                                <OutlinedInput
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    multiline
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                title="Отправить"
                                                disabled={loading}
                                                onClick={handleTicketSave}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Оставить комментарий"
                                />
                            </FormControl>
                        )}
                        <Comments comments={ticket.comments} />
                    </Box>
                </Box>
            )}
        </Layout>
    );
};

export default DetailTicket;
