import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import {
    Container,
    Typography,
    Box,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Card,
    CardContent,
    Tooltip,
    Button,
    IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import CompanyService from "../../services/CompanyService";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const CompanyEdit = () => {
    const [additionalContactPerson, setAdditionalContactPerson] = useState(0);
    const navigate = useNavigate();
    const params = useParams();
    const [company, setCompany] = useState(null);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingCompanyRemove, setLoadingCompanyRemove] = useState(false);
    const [removeCompanyDialogOpen, setRemoveCompanyDialogOpen] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        async function updateCompany() {
            setLoadingSubmit(true);
            try {
                const company_updated = await CompanyService.updateCompany(params.id, company);
                setCompany(company_updated.data);
                enqueueSnackbar(<Typography>Изменения применены</Typography>);
            } catch (e) {
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            } finally {
                setLoadingSubmit(false);
            }
        }
        updateCompany();
    };

    const handleCompanyRemove = (e) => {
        e.preventDefault();
        async function removeCompany() {
            setLoadingCompanyRemove(true);
            try {
                await CompanyService.removeCompany(params.id);
                enqueueSnackbar(<Typography>Компания успешно удалена</Typography>);
                navigate("/companies");
            } catch (e) {
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            } finally {
                setLoadingCompanyRemove(false);
            }
        }
        removeCompany();
    };

    const addContactPerson = () => {
        setCompany((old) => {
            const newCustomId = additionalContactPerson + 1;
            setAdditionalContactPerson(newCustomId);
            let contactperson_set = company.contactperson_set;
            contactperson_set.push({
                fullname: "",
                phone_number: "",
                email: "",
                comment: "",
                custom_id: newCustomId,
                company: company.id,
            });
            return { ...old, contactperson_set: contactperson_set };
        });
    };

    const removeContactPerson = (contactperson) => {
        setCompany((old) => {
            const new_persons = old.contactperson_set.filter((el) => {
                if (contactperson.id) {
                    return el.id !== contactperson.id;
                }
                return el.custom_id !== contactperson.custom_id;
            });
            return {
                ...old,
                contactperson_set: new_persons,
            };
        });
    };

    useEffect(() => {
        async function fetchCompany() {
            try {
                const company = await CompanyService.fetchCompany(params.id);
                setCompany(company.data);
            } catch (e) {
                if (e.response.status === 404) {
                    navigate("*");
                } else {
                    enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
                }
            }
        }
        fetchCompany();
    }, []);
    return (
        <div>
            <Header />
            <Container maxWidth="xl" sx={{ py: 4 }}>
                {company ? (
                    <Box component="form" onSubmit={handleSubmit}>
                        <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
                            Редактирование компании
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid container item spacing={3}>
                                <Grid item md={1} xs={12}>
                                    <TextField
                                        id="id"
                                        name="id"
                                        variant="standard"
                                        label="ID"
                                        fullWidth
                                        value={company.id}
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={11} xs={12}>
                                    <TextField
                                        id="name"
                                        name="name"
                                        required
                                        variant="standard"
                                        label="Название"
                                        fullWidth
                                        value={company.name}
                                        onChange={(event) =>
                                            setCompany((old) => ({ ...old, name: event.target.value }))
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item md={10} xs={12}>
                                    <TextField
                                        id="address"
                                        name="address"
                                        variant="standard"
                                        label="Адрес"
                                        fullWidth
                                        value={company.address}
                                        required
                                        onChange={(event) =>
                                            setCompany((old) => ({ ...old, address: event.target.value }))
                                        }
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TextField
                                        id="work_time"
                                        name="work_time"
                                        variant="standard"
                                        label="Время работы"
                                        fullWidth
                                        value={company.work_time}
                                        onChange={(event) =>
                                            setCompany((old) => ({ ...old, work_time: event.target.value }))
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        id="comment"
                                        name="comment"
                                        variant="standard"
                                        label="Комментарий"
                                        fullWidth
                                        multiline
                                        value={company.comment}
                                        onChange={(event) =>
                                            setCompany((old) => ({ ...old, comment: event.target.value }))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography
                            component="h1"
                            variant="h5"
                            sx={{ mt: 4, mb: 2, display: "flex", alignItems: "center" }}
                        >
                            Контактные лица{" "}
                            <Tooltip title="Добавить контактное лицо">
                                <IconButton sx={{ ml: 1 }} onClick={addContactPerson}>
                                    <AddCircleIcon color="primary" fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        {company.contactperson_set.length ? (
                            <Box sx={{ mt: 2 }}>
                                <Grid container spacing={3}>
                                    {company.contactperson_set.map((contact_person) => {
                                        return (
                                            <Grid key={contact_person.id} item md={6} xs={12}>
                                                <Card variant="outlined">
                                                    <CardContent>
                                                        <Grid container spacing={2}>
                                                            <Grid container item spacing={0}>
                                                                <Grid item md={12} xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                                                                    <TextField
                                                                        sx={{width: "85%"}}
                                                                        name="fullname"
                                                                        variant="standard"
                                                                        label="Имя"
                                                                        required
                                                                        value={contact_person.fullname}
                                                                        onChange={(event) =>
                                                                            setCompany((old) => {
                                                                                const new_persons =
                                                                                    old.contactperson_set.map((el) => {
                                                                                        if (
                                                                                            (contact_person.id &&
                                                                                                contact_person.id ===
                                                                                                    el.id) ||
                                                                                            (contact_person.custom_id &&
                                                                                                contact_person.custom_id ===
                                                                                                    el.custom_id)
                                                                                        ) {
                                                                                            el.fullname =
                                                                                                event.target.value;
                                                                                        }
                                                                                        return el;
                                                                                    });
                                                                                return {
                                                                                    ...old,
                                                                                    contactperson_set: new_persons,
                                                                                };
                                                                            })
                                                                        }
                                                                    />
                                                                    <Tooltip title="Удалить контактное лицо">
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                removeContactPerson(contact_person)
                                                                            }
                                                                        >
                                                                            <RemoveCircleIcon
                                                                                color="error"
                                                                                fontSize="large"
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item spacing={2}>
                                                                <Grid item md={6} xs={12}>
                                                                    <TextField
                                                                        name="phone_number"
                                                                        variant="standard"
                                                                        label="Телефон"
                                                                        fullWidth
                                                                        sx={{ mt: 2 }}
                                                                        value={contact_person.phone_number}
                                                                        onChange={(event) =>
                                                                            setCompany((old) => {
                                                                                const new_persons =
                                                                                    old.contactperson_set.map((el) => {
                                                                                        if (
                                                                                            (contact_person.id &&
                                                                                                contact_person.id ===
                                                                                                    el.id) ||
                                                                                            (contact_person.custom_id &&
                                                                                                contact_person.custom_id ===
                                                                                                    el.custom_id)
                                                                                        ) {
                                                                                            el.phone_number =
                                                                                                event.target.value;
                                                                                        }
                                                                                        return el;
                                                                                    });
                                                                                return {
                                                                                    ...old,
                                                                                    contactperson_set: new_persons,
                                                                                };
                                                                            })
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item md={6} xs={12}>
                                                                    <TextField
                                                                        name="mail"
                                                                        variant="standard"
                                                                        label="Почта"
                                                                        fullWidth
                                                                        sx={{ mt: 2 }}
                                                                        value={contact_person.mail}
                                                                        onChange={(event) =>
                                                                            setCompany((old) => {
                                                                                const new_persons =
                                                                                    old.contactperson_set.map((el) => {
                                                                                        if (
                                                                                            (contact_person.id &&
                                                                                                contact_person.id ===
                                                                                                    el.id) ||
                                                                                            (contact_person.custom_id &&
                                                                                                contact_person.custom_id ===
                                                                                                    el.custom_id)
                                                                                        ) {
                                                                                            el.mail =
                                                                                                event.target.value;
                                                                                        }
                                                                                        return el;
                                                                                    });
                                                                                return {
                                                                                    ...old,
                                                                                    contactperson_set: new_persons,
                                                                                };
                                                                            })
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item spacing={2}>
                                                                <Grid item md={12} xs={12}>
                                                                    <TextField
                                                                        name="comment"
                                                                        variant="standard"
                                                                        label="Комментарий"
                                                                        fullWidth
                                                                        sx={{ mt: 2 }}
                                                                        value={contact_person.comment}
                                                                        onChange={(event) =>
                                                                            setCompany((old) => {
                                                                                const new_persons =
                                                                                    old.contactperson_set.map((el) => {
                                                                                        if (
                                                                                            (contact_person.id &&
                                                                                                contact_person.id ===
                                                                                                    el.id) ||
                                                                                            (contact_person.custom_id &&
                                                                                                contact_person.custom_id ===
                                                                                                    el.custom_id)
                                                                                        ) {
                                                                                            el.comment =
                                                                                                event.target.value;
                                                                                        }
                                                                                        return el;
                                                                                    });
                                                                                return {
                                                                                    ...old,
                                                                                    contactperson_set: new_persons,
                                                                                };
                                                                            })
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        ) : (
                            <></>
                        )}
                        <Box sx={{ mt: 4 }}>
                            <LoadingButton loading={loadingSubmit} type="submit" variant="contained">
                                Сохранить
                            </LoadingButton>
                            <Button
                                sx={{ ml: 2 }}
                                variant="contained"
                                color="error"
                                onClick={() => setRemoveCompanyDialogOpen(true)}
                            >
                                Удалить
                            </Button>
                            <Dialog open={removeCompanyDialogOpen} onClose={() => setRemoveCompanyDialogOpen(false)}>
                                <DialogTitle>Удаление компании "{company.name}"</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Подтвердите удаление компании. При этом будут удалены все соответствущие
                                        контактные лица и заявки.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="secondary" onClick={() => setRemoveCompanyDialogOpen(false)}>
                                        Отмена
                                    </Button>
                                    <LoadingButton
                                        loading={loadingCompanyRemove}
                                        type="submit"
                                        variant="contained"
                                        onClick={handleCompanyRemove}
                                    >
                                        Подтвердить
                                    </LoadingButton>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
            </Container>
        </div>
    );
};

export default CompanyEdit;
