import { useContext } from "react";
import { useState } from "react";
import AuthContext from "../context/AuthContext";
import { observer } from "mobx-react-lite";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TexttField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { store } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const res = await store.login(username, password);
        setLoading(false);
        if (!res.success) {
            setError(res.message);
        } else {
            navigate("/");
        }
    };

    return (
        <div>
            <Container maxWidth="sm">
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Вход
                    </Typography>
                    <TexttField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        name="username"
                        autoFocus
                        label="Логин"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TexttField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        name="password"
                        type="password"
                        label="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <LoadingButton
                        loading={loading}
                        loadingPosition="end"
                        endIcon={<></>}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2 }}
                    >
                        Войти
                    </LoadingButton>
                    <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            {error ? (
                                <Alert severity="warning" sx={{ mt: 2 }}>
                                    {error}
                                </Alert>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
};

export default observer(LoginPage);
