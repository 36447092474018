import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL

const api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})

api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('authTokens')).access}`
    return config;
})

api.interceptors.response.use((config) => {
    return config
}, async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true
        try {
            const initial_token = JSON.parse(localStorage.getItem('authTokens'))
            const response = await axios.post(`${API_URL}/token/refresh/`, { refresh: initial_token.refresh })
            initial_token.access = response.data.access
            localStorage.setItem('authTokens', JSON.stringify(initial_token))
            return api.request(originalRequest)
        } catch (e) {
            localStorage.removeItem('authTokens')
            window.location.href = '/login'
        }        
    }
    throw error
}
)

export default api;