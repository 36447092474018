import { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { observer } from "mobx-react-lite";
import { Backdrop, CircularProgress } from '@mui/material';

const PrivateRoute = () => {
    const { store } = useContext(AuthContext)

    useEffect(() => {
        if (localStorage.getItem('authTokens')) {
            store.checkAuth()
        }
    }, [])

    if (store.isLoading) {
        return <Backdrop
            open={true}
        >
            <CircularProgress color='primary' />
        </Backdrop>

    } else if (store.isAuth) {
        return <Outlet />
    } else {
        return <Navigate to='/login' />
    }
}

export default observer(PrivateRoute);