import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/x-data-grid';
import GlobalStyles from '@mui/material/GlobalStyles';
import MyRoutes from "./components/MyRoutes";
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { blueGrey } from '@mui/material/colors';

const defaultTheme = createTheme(
  {palette: {
    secondary: blueGrey
  }},
  ruRU,
)

const App: FC = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <GlobalStyles styles={{ body: { padding: 0, margin: 0 } }} />
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <BrowserRouter>
            <AuthProvider>
              <MyRoutes />
            </AuthProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
