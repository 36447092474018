import { Routes, Route } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import HomePage from "../pages/HomePage";
import CreateTicket from "../pages/CreateTicket";
import PrivateRoute from "../utils/PrivateRoute";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import { observer } from "mobx-react-lite";
import DetailTicket from "../pages/DetailTicket";
import Companies from "../pages/companies/Companies";
import CompanyEdit from "../pages/companies/CompanyEdit";
import CompanyCreate from "../pages/companies/CompanyCreate";
import PageNotFound from "../pages/errors/PageNotFound";
import ProfilePage from "../pages/ProfilePage";

const MyRoutes = () => {
    const { store } = useContext(AuthContext);

    return (
        <div>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<HomePage />} />
                </Route>
                <Route path="/ticket/:id" element={<PrivateRoute />}>
                    <Route path="/ticket/:id" element={<DetailTicket />} />
                </Route>
                <Route path="/profile" element={<PrivateRoute />}>
                    <Route path="/profile" element={<ProfilePage />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
                {store.user.permissions &&
                (store.user.permissions.includes("couriers.custom_perform_initiator_role") ||
                    store.user.permissions.includes("couriers.custom_perform_manager_role")) ? (
                    <Route path="/create-ticket" element={<PrivateRoute />}>
                        <Route path="/create-ticket" element={<CreateTicket />} />
                    </Route>
                ) : (
                    <></>
                )}
                {store.user.permissions && store.user.permissions.includes("couriers.custom_perform_manager_role") ? (
                    <Route path="/companies" element={<PrivateRoute />}>
                        <Route path="/companies" element={<Companies />} />
                    </Route>
                ) : (
                    <></>
                )}
                {store.user.permissions && store.user.permissions.includes("couriers.custom_perform_manager_role") ? (
                    <Route path="/companies/:id" element={<PrivateRoute />}>
                        <Route path="/companies/:id" element={<CompanyEdit />} />
                    </Route>
                ) : (
                    <></>
                )}
                {store.user.permissions && store.user.permissions.includes("couriers.custom_perform_manager_role") ? (
                    <Route path="/company-create" element={<PrivateRoute />}>
                        <Route path="/company-create" element={<CompanyCreate />} />
                    </Route>
                ) : (
                    <></>
                )}
            </Routes>
        </div>
    );
};

export default observer(MyRoutes);
