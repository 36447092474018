import React from "react";
import { makeAutoObservable, runInAction } from "mobx";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import TicketService from "../../services/TicketService";

interface ServerContactPerson {
    id: number;
    fullname: string;
    mail: string | null;
    phone_number: string | null;
    company: number;
    comment: string | null;
}

interface InputContactPerson {
    inputValue: string;
    fullname: string;
}

interface ServerCompany {
    id: number;
    name: string;
    address: string | null;
    work_time: string | null;
    comment: string | null;
    contactperson_set: Array<ServerContactPerson>;
}

interface InputCompany {
    inputValue: string;
    name: string;
}

interface FunctionResult {
    success: boolean;
    message: string;
}

interface TicketForm {
    id?: number;
    name?: string;
    address: string;
}

interface CompanyForm {
    id?: number;
    fullname?: string;
    phone_number: string;
}

class NewTicketStore {
    task: string = "";
    isExpress: boolean = false;
    expiresAt: Dayjs = dayjs();
    expiresAtError: boolean = false;
    comment: string = "";

    contactPerson: ServerContactPerson | InputContactPerson | null = null;
    contactPersonError: boolean = false;
    phoneNumber: string = "";
    phoneNumberError: boolean = false;

    company: ServerCompany | InputCompany | null = null;
    companyAddress: string = "";
    companyAddressError: boolean = false;

    serverContactPersons: Array<ServerContactPerson> = [];
    serverCompanies: Array<ServerCompany> = [];

    contactPersonDisabled: boolean = true;
    loadingSubmit: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setTask = (task: string) => {
        this.task = task;
    };

    setComment = (comment: string) => {
        this.comment = comment;
    };

    setIsExpress = (bool: boolean) => {
        this.isExpress = bool;
    };

    setNewExpirationDate = (date: Dayjs) => {
        this.expiresAt = date;
        this.expiresAtError = false;
    };

    setExpirationDateError = (bool: boolean) => {
        this.expiresAtError = bool;
    };

    companyChange = async (
        event: React.SyntheticEvent,
        newValue: ServerCompany | InputCompany | null
    ): Promise<FunctionResult> => {
        let result = {
            success: true,
            message: "",
        };
        this.company = newValue;
        this.contactPerson = null;
        this.contactPersonError = false;
        this.companyAddressError = false;
        this.phoneNumberError = false;

        // Если это существующая компания
        if (newValue && "id" in newValue) {
            this.companyAddress = newValue.address ? newValue.address : "";
            // запросим всех контактных лиц для компании
            try {
                const contactPersons = await TicketService.fetchCompanyContactPersons(newValue.id);
                runInAction(() => {
                    this.serverContactPersons = contactPersons.data;

                    this.contactPersonDisabled = false;
                    this.companyAddressError = false;
                });
            } catch (e) {
                console.error(e);
                result.success = false;
                result.message = "Ошибка запроса контактных лиц";
            }
        }
        // если это новая компания
        else if (newValue && "inputValue" in newValue) {
            this.companyAddress = "";
            this.contactPersonDisabled = false;
            this.phoneNumber = "";
        }
        // если просто удаляем поле
        else {
            this.companyAddress = "";
            this.contactPersonDisabled = true;
            this.phoneNumber = "";
        }

        return result;
    };

    companyAddressChange = (value: string) => {
        this.companyAddress = value;
        this.companyAddressError = false;
    };

    // setContactPerson = (contactPerson: ServerContactPerson | InputContactPerson | null) => {
    //     this.contactPerson = contactPerson;
    // };

    contactPersonChange = (event: React.SyntheticEvent, newValue: ServerContactPerson | InputContactPerson | null) => {
        this.contactPerson = newValue;
        this.phoneNumberError = false;
        this.contactPersonError = false;

        // если это новое контактное лицо или поле в принципе очищается
        if ((newValue && "inputValue" in newValue) || !newValue) {
            this.phoneNumber = "";
            // если существующее контактное лицо
        } else {
            this.phoneNumber = newValue.phone_number ? newValue.phone_number : "";
        }
    };

    phoneNumberChange = (value: string) => {
        this.phoneNumber = value;
        this.phoneNumberError = false;
    };

    createTicket = async (event: React.SyntheticEvent): Promise<FunctionResult> => {
        let result = {
            success: true,
            message: "",
        };

        event.preventDefault();

        // сначала проверим ошибки в форме
        let error_flag = false;
        if (!this.expiresAt.isValid()) {
            this.expiresAtError = true;
            error_flag = true;
        }
        if (this.contactPerson && !this.phoneNumber.trim()) {
            this.phoneNumberError = true;
            error_flag = true;
        }
        if (this.phoneNumber.trim() && !this.contactPerson) {
            this.contactPersonError = true;
            error_flag = true;
        }
        if (!this.companyAddress.trim()) {
            this.companyAddressError = true;
            error_flag = true;
        }
        if (error_flag) {
            result.success = false;
            return result;
        }

        // попробуем создать заявку
        this.loadingSubmit = true;
        try {
            let company: TicketForm = { address: this.companyAddress };
            let contact_person: CompanyForm | null = null;

            if (this.company && "id" in this.company) {
                company.id = this.company.id;
            } else if (this.company && "inputValue" in this.company) {
                company.name = this.company.inputValue;
            }

            if (this.contactPerson) {
                contact_person = { phone_number: this.phoneNumber };
                if (this.contactPerson && "id" in this.contactPerson) {
                    contact_person.id = this.contactPerson.id;
                } else {
                    contact_person.fullname = this.contactPerson.inputValue;
                }
            }

            await TicketService.createTicket(
                this.task,
                company,
                contact_person,
                this.expiresAt.format("YYYY-MM-DD"),
                this.isExpress,
                this.comment
            );
            result.message = "Заявка успешно создана";
        } catch (e) {
            console.error(e);
            result.success = false;
            result.message = "Ошибка создания заявки";
        }
        runInAction(() => (this.loadingSubmit = false));

        return result;
    };
}
export default NewTicketStore;
