import { Link, useNavigate } from "react-router-dom";
import { Typography, Toolbar, AppBar, Box, Button } from "@mui/material";
import ProfileIcon from "./header/ProfileIcon";
import MyMenuIcon from "./header/MyMenuIcon";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const Header = () => {
    const { store } = useContext(AuthContext);
    const navigate = useNavigate();
    return (
        <AppBar position="static" color="primary">
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        <Link className="unstyled-link" to="/">
                            Avilex Courier
                        </Link>
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ ml: 4 }}
                        onClick={() => navigate("/")}
                    >
                        Главная
                    </Button>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {(store.user.permissions.includes("couriers.custom_perform_initiator_role") ||
                        store.user.permissions.includes("couriers.custom_perform_manager_role")) && (
                        <MyMenuIcon sx={{ mr: 2 }} />
                    )}
                    <ProfileIcon />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
