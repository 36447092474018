import { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Box,
    Button,
    FormControl,
    DialogActions,
    Typography,
    Autocomplete,
    TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import localizedFormat from "dayjs/plugin/localizedFormat";
import TicketService from "../../services/TicketService";
import { enqueueSnackbar } from "notistack";

dayjs.locale("ru");
dayjs.extend(localizedFormat);

const ChangeStatus = (props) => {
    const ticket = props.ticket;
    const setTicket = props.setTicket;
    const serverCouriers = props.serverCouriers;
    const { store } = useContext(AuthContext);
    const [courierDialogOpen, setCourierDialogOpen] = useState(false);
    const [courierFormState, setCourierFormState] = useState({
        courier: null,
        departure_date: dayjs(),
    });
    const [departureDateError, setDepartureDateError] = useState(false);

    const handleCourierDialogOpen = () => {
        setCourierDialogOpen(true);
    };

    const handleCourierDialogClose = () => {
        setCourierDialogOpen(false);
    };

    const handleCouriersSubmit = (e) => {
        e.preventDefault();
        if (!courierFormState.departure_date.isValid()) {
            setDepartureDateError(true);
            return;
        }
        async function assignToCourier() {
            try {
                const ticket_data = await TicketService.assignToCourier(
                    props.ticket.id,
                    courierFormState.courier.id,
                    courierFormState.departure_date.format("YYYY-MM-DD")
                );
                setTicket({
                    ...ticket_data.data,
                    contact_person: ticket_data.data.contact_person ? ticket_data.data.contact_person : {},
                });
                enqueueSnackbar(<Typography>Заявке назначен курьер</Typography>);
            } catch (e) {
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            } finally {
                setCourierDialogOpen(false);
            }
        }
        assignToCourier();
    };

    const handleInProgress = (e) => {
        e.preventDefault();
        async function inProgress() {
            try {
                const ticket_data = await TicketService.inProgress(ticket.id);
                setTicket({
                    ...ticket_data.data,
                    contact_person: ticket_data.data.contact_person ? ticket_data.data.contact_person : {},
                });
                enqueueSnackbar(<Typography>Заявка взята в работу</Typography>);
            } catch (e) {
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            }
        }
        inProgress();
    };

    const handleComplete = (e) => {
        e.preventDefault();
        async function complete() {
            try {
                const ticket_data = await TicketService.complete(ticket.id);
                setTicket({
                    ...ticket_data.data,
                    contact_person: ticket_data.data.contact_person ? ticket_data.data.contact_person : {},
                });
                enqueueSnackbar(<Typography>Заявка выполнена</Typography>);
            } catch (e) {
                enqueueSnackbar(<Typography>Внутрення ошибка сервера</Typography>);
            }
        }
        complete();
    };

    return (
        <div>
            {store.user.permissions.includes("couriers.custom_perform_manager_role") &&
            ticket.status.code_name === "new" ? (
                <Box component="div">
                    <Button onClick={handleCourierDialogOpen}>
                        Назначить курьера
                    </Button>
                    <Dialog open={courierDialogOpen} onClose={handleCourierDialogClose}>
                        <DialogTitle>Назначение курьера</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Чтобы отправить курьера на выезд, необходимо заполнить все поля ниже.
                            </DialogContentText>
                            <Box sx={{ mt: 2 }} component="form" id="courierForm" onSubmit={handleCouriersSubmit}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="courier"
                                        name="courier"
                                        options={serverCouriers}
                                        onChange={(event, newValue) => {
                                            setCourierFormState((old) => ({
                                                ...old,
                                                courier: { ...newValue },
                                            }));
                                        }}
                                        disableClearable
                                        value={courierFormState.courier}
                                        renderInput={(params) => {
                                            return <TextField required {...params} label="Курьер" />;
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                                <Box component="li" {...props}>
                                                    {`${option.last_name} ${option.first_name}`}
                                                </Box>
                                            );
                                        }}
                                        getOptionLabel={(option) => {
                                            if (typeof option === "string") {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return `${option.last_name} ${option.first_name}`;
                                        }}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.id === value.id;
                                        }}
                                    />                                    
                                </FormControl>
                                <FormControl fullWidth variant="standard">
                                    <DatePicker
                                        label="Дата выезда"
                                        value={courierFormState.departure_date}
                                        onChange={(newValue) =>
                                            setCourierFormState((old) => {
                                                setDepartureDateError(false);
                                                return { ...old, departure_date: newValue };
                                            })
                                        }
                                        sx={{ mt: 2 }}
                                        slotProps={{
                                            textField: {
                                                error: departureDateError,
                                                helperText: departureDateError ? "Неверный формат даты" : "",
                                                required: true,
                                            },
                                        }}
                                        format="LL"
                                        views={["year", "month", "day"]}
                                    />
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCourierDialogClose}>Отмена</Button>
                            <Button form="courierForm" type="submit">
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            ) : (
                <></>
            )}

            {ticket.status.code_name === "assigned_to_courier" &&
            (store.user.permissions.includes("couriers.custom_perform_manager_role") ||
                store.user.permissions.includes("couriers.custom_perform_courier_role")) ? (
                <Button onClick={handleInProgress}>
                    Взять в работу
                </Button>
            ) : (
                <></>
            )}

            {ticket.status.code_name === "in_progress" &&
            (store.user.permissions.includes("couriers.custom_perform_manager_role") ||
                store.user.permissions.includes("couriers.custom_perform_courier_role")) ? (
                <Button onClick={handleComplete}>
                    Выполнить
                </Button>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ChangeStatus;
